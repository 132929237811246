import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Project} from '@app/modules/shared/models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  getProjectError,
  IProjectState,
  ProjectList,
  ResetProjectState,
  getPaginatedProjects,
  ProjectGet, getProject
} from '@app/stores';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-campaign-copy',
  templateUrl: './campaign-copy.component.html',
  styleUrls: ['./campaign-copy.component.scss']
})
export class CampaignCopyComponent implements OnInit, OnDestroy {

  public campaignForm: UntypedFormGroup;
  submitted = false;
  unsubscriber = new Subject();
  public projects: Project[] = [];
  public projectByClient: any = [];
  @Input() projectId: number;
  selectedProject: any;

  constructor(
    public modal: NgbActiveModal,
    public fb: UntypedFormBuilder,
    private projectStore: Store<IProjectState>,
    private toastr: ToastrService
  ) {
    this.projectStore.dispatch(ResetProjectState({params: {error: '', projects: []}}));
  }

  get f() {
    return this.campaignForm.controls;
  }

  subscribeStores() {
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.selectedProject = project;
        }
      });
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error);
        }
      });
    this.projectStore.pipe(select(getPaginatedProjects))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedProjects => {
          if (paginatedProjects) {
            this.projects = paginatedProjects.list;
            const projectIds = this.projects.map(item => item.id);
            if (this.selectedProject && !projectIds.includes(this.selectedProject.id)) {
              this.projects = [...this.projects, this.selectedProject]
            }
            this.projects = this.projects.map(project => ({
              ...project,
              title: `${project?.client?.name} - ${project?.title}`
            }));
            // this.groupProjects();
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.projectId) {
      this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
    }
    this.loadAllProjects();
    this.subscribeStores();
    this.campaignForm = this.fb.group({
      name: ['', [Validators.required]],
      project_id: [this.projectId, [Validators.required]],
      with_influencers: [false],
    });
  }

  saveProject() {
    this.submitted = true;
    if (this.campaignForm.invalid) {
      return;
    }
    this.modal.close(this.campaignForm.value);
  }

  handleSearchValue(searchTerm) {
    this.loadAllProjects(searchTerm.term);
  }

  handleProjectSelection(selectedProject: any) {
    if (selectedProject && selectedProject.value) {
      this.campaignForm.get('project_id')?.setValue(selectedProject.value)
    } else {
      this.loadAllProjects();
      this.campaignForm.get('project_id')?.setValue(null);
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }

  loadAllProjects(search = '', page = 1, perPage = 10, orderBy= {'column': 'created_at', 'dir': 'desc'}, fields = ['id', 'title', 'created_at'], clientFields = ['id', 'name']) {
    let option: any = {includePagination: true, orderBy, fields};
    this.projectStore.dispatch(ProjectList({
      params: {
        options: JSON.stringify(option),
        page: page,
        perPage: perPage,
        search,
      }
    }));
  }

}
